// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

//Theme cards
@mixin card {
    background: white;
    padding: 30px 25px;
    border-radius: $radius;
    box-shadow: $shadow;
    @include mq($until: sm) {
        padding: 20px 15px;
    }
}

@mixin bg {
    background: $color-main center center no-repeat;
    background-size: cover;
}

@mixin transitions {
    transition: 0.3s ease-in-out;
    &:hover {
        transition: 0.3s ease-in-out;
    }
}