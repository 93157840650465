.header {
  width: 100%;
  position: absolute;
  background: #000000c7;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    padding: 0;
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 3.7vw;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: white;
    font-size: rem(21px);
    font-weight: $fw-bold;
    @include mq($until: xl) {
      font-size: rem(18px);
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &:hover {
      color: $color-main;
    }
  }
}

.header-inner {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 32px;
  padding-bottom: 32px;
  @include mq($until: lg) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include mq($until: md) {
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }
}

.header-contact {
  position: absolute;
  width: 280px;
  height: 40px;
  right: 8vw;
  top: 20px;
  background: #ffffff21;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &> * {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }
  svg {
    fill: $color-main;
    width: 20px;
    margin: 10px;
  }
  @include mq($until: md) {
    position: initial;
    margin-left: 10vw;
    margin-bottom: 4px;
  }
  @include mq($until: sm) {
    margin-left: 0;
    padding-top: 2px;
    width: 135px;
    height: 30px;
    * {
      font-size: rem(14px);
    }
    span {
      display: none;
    }
    svg {
      margin-left: 0;
      width: 15px;
    }
  }
}


.langs form {
  position: absolute;
  top: 20px;
  right: 2vw;

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 3px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #b1b1b1;
    background-color: #fff0;
    background-clip: padding-box;
    border: 1px solid #a7acb100;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
    &:focus {
      color: #b1b1b1;
      background-color: #fff0;
      background-clip: padding-box;
      border: 1px solid #a7acb100;
      outline: 0;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
    > option {
      background-color: rgb(54, 54, 54);
    }
  }

  @include mq($until: md) {
    top: 15vw;
    right: 3vw;

    .form-control {
      color: black;
      &:focus {
        color: black;
      }
      > option {
        background-color: #e6e6e6;
      }
    }
  }

  @include mq($until: xs) {
    top: 25vw;
  }
}
