//Intro

.intro {
  height: 100vh;
  @include bg;
  h1 {
    text-align: end;
    position: relative;
    top: 55vh;
    color: white;
    font-size: rem(78px);
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    @include mq($until: md) {
      font-size: rem(48px);
    }
    @include mq($until: xs) {
      font-size: rem(35px);
    }
    strong {
      color: $color-main;
    }
  }
}

//Offer

.offer {
  background: $secondary-bg;
  display: flex;
  .padding-container {
    z-index: 1;
    display: flex;
    flex-flow: wrap;
    margin-top: -70px;
    margin-bottom: -70px;
    @include mq($until: md) {
      margin-top: -30px;
      margin-bottom: -30px;
    }
    div {
      padding: 11px;
      @include mq($until: xs) {
        padding: 10px 0;
      }
      a {
        display: block;
        height: 350px;
        @include card;
        @include transitions;
        &:hover {
          box-shadow: $shadow-hover
        }
        @include mq($until: xl) {
          height: 290px;
        }
        @include mq($until: lg) {
          height: 270px;
        }
        img {
          height: 95%;
          display: block;
          margin: 0 auto;
          object-fit: contain;
        }
      }
    }
    h5 {
      font-size: rem(33px);
      color: $color-main;
    }
    h6, span {
      font-weight: bold;
      font-size: rem(18px);
      color: $color-font-dark;
      @include mq($until: xl) {
        font-size: rem(16px);
      }
    }
    span {
      line-height: 169.5%;
    }
  }
  #underlined {
    color: $color-main;
    text-decoration: underline;
    margin-top: 25px;
    display: inline-block;
  }
}

//About

.about {
  background: #f0f2f1 center center no-repeat;
  background-size: 100%;
  background-position-y: top;
  // height: 38vw;
  position: relative;
  @include mq($until: md) {
    // height: 47vw;
  }
  &>div{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .padding-container {
    h3 {
      color: white;
      position: absolute;
      top: 20vw;
      @include mq($until: md) {
        top: 10vw;
      }
    }
    &> div {
      @include card;
      position: relative;
      top: 25vw;
      display: flex;
      display: -webkit-box;
      justify-content: space-between;
      @include mq($until: md) {
        top: 27vw;
      }
      @include mq($until: sm) {
        display: block;
        .box-img img{
          display: none;
        }
      }
      @include mq($until: xs) {
        top: 28vw;
      }

    }
  }
  h6 {
    white-space: nowrap;
    margin-bottom: 35px;
    @include mq($until: xl) {
      white-space: initial;
    }
  }
}

.about-caption {
  padding: 0;
}

.box-img {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  img {
    display: block;
    margin: 0 auto;
    position: relative;
    top: -70px;
    object-fit: contain;
    margin-bottom: -20px;
    width: 20vw;
    @include mq($until: lg) {
      right: 24px;
    }
    @include mq($until: md) {
      right: 0;
    }
  }
  a {
    text-decoration: underline;
    font-size: rem(20px);
    color: black;
    font-weight: bold;
    strong{
      color: $color-main;
      text-decoration: underline;
      text-decoration-color: $color-main;
    }
    &:hover {
      color: $color-main;
    }
    @include mq($until: xl) {
      margin-left: 40px;
    }
    @include mq($until: md) {
      font-size: rem(18px);
    }
    @include mq($until: sm) {
      text-align: end;
      margin-top: 30px;
    }
  }
}

//Tech

.tech {
  display: flex;
  flex-direction: column;
  background: $secondary-bg;
  padding-bottom: 10vw;
  @include mq($until: xxl) {
    padding-bottom: 15vw;
  }
  @include mq($until: xs) {
    padding-bottom: 30vw;
  }
  @include mq($until: xxs) {
    padding-bottom: 125px;
  }
  .padding-container {
    margin-top: 700px;
    flex-flow: wrap;
    & > div:nth-child(3) {
      padding: 0;
      @include mq($until: lg) {
        flex-flow: wrap;
        & > div {
          padding: 0 15px 30px;
        }
      }
      @include mq($until: xs) {
        & > div {
          padding: 0 0 10px;
        }
      }
    }
    @include mq($until: xxl) {
      margin-top: 500px;
    }
    @include mq($until: md) {
      margin-top: 350px;
    }
    @include mq($until: xs) {
      margin-top: 250px;
    }
    @include mq($until: xxs) {
      margin-top: 150px;
    }
  }
  h3 {
    margin-bottom: 50px;
  }
  .text-margin {
    margin-right: 0;
  }
}

.item {
  text-align: center;
  @include card;
  padding: 20px 10px;
  height: auto;
  svg {
    width: 72px;
    fill: $color-main;
  }
  h6 {
    font-size: .9vw;
    max-width: 80%;
    display: block;
    margin: 30px auto 0;
  }
  @include mq($until: xl) {
    padding: 20px 0;
    svg {
      width: 50px;
    }
  }
  @include mq($until: lg) {
    h6 {
      font-size: rem(20px);
    }
  }
}

.caption-part {
  &> div:last-of-type {
    flex-flow: wrap;
    &> div {
      margin-bottom: 30px;
    }
  }
  @include mq($until: sm) {
    flex-flow: wrap;
    &> div:last-of-type {
      padding: 0;
    }
  }
}

.tech-gallery {
  width: 100%;
  margin-top: 100px;
}
