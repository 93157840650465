.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    background: $secondary-bg;
    .padding-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
  a {
    color: $paragraph-color;
  }
  @include mq($until: xs) {
    a, div, span {
      font-size: 10px;
    }
  }
}

footer {
  background: #121212;
}

.footer-menu a {
  color: black;
  font-weight: bold;
  font-size: 16px;
}

.footer-inner {
  @include card;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -90px;
  ul {
    min-width: 915px;
    padding: 0;
    max-width: 940px;
  }
  ul {
    display: flex;
    flex-flow: wrap;
    li::before {
      content: '|';
      position: relative;
      color: $color-main;
      top: 0;
      padding-right: 20px;
      // display: inline-block;
      display: none;
      border: #fff0 0;
      border-radius: 0;
      background-color: #fff0;
    }
    li:first-child:before {
      display: none;
    }
    li:nth-child(4):before {
      display: none;
    }
    @include mq($until: xl) {
      // li:nth-child(4):before {
      //   display: inline-block;
      // }
      // li:nth-child(3):before {
      //   display: none;
      // }
      // li:nth-child(5):before {
      //   display: none;
      // }
    }
    @include mq($until: xl) {
      min-width: 750px;
      li:before {
        display: none;
      }
    }
  }
  @include mq($until: lg) {
    flex-flow: column;
    ul {
      justify-content: center;
    }
  }
  @include mq($until: md) {
    li {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  @include mq($until: sm) {
    ul {
      flex-direction: column;
      align-items: center;
      // li:before {
      //   display: none!important;
      //   text-align: center;
      // }
    }
  }
  @include mq($until: xs) {
    ul {
      min-width: fit-content;
    }
    li {
      text-align: center;
      a {
        font-size: 15px;
      }
    }
  }
}

.footer-contacts {
  display: flex;
  margin-bottom: 60px;
  @include mq($until: lg) {
    flex-flow: column;
  }
  @include mq($until: md) {
    margin-bottom: 30px;
    &>div {
      padding: 0;
    }
  }
  a, p {
    color: white;
    line-height: 2;
    min-width: fit-content;
    @include mq($until: xl) {
      line-height: 1.8;
    }
  }
  &> div {
    &:first-of-type {
      white-space: pre-line;
      min-width: 270px;
      br {
        display: none;
      }
      @include mq($until: xl) {
        margin-right: 10px;
      }
      @include mq($until: lg) {
        display: flex;
        justify-content: space-between;
        div {
          margin-left: 40px;
          display: flex;
          flex-flow: wrap;
          justify-content: flex-end;
          a {
            margin-right: 25px;
          }
        }
      }
      @include mq($until: xs) {
        div {
          margin-left: 20px;
        }
      }
      @include mq($until: xxs) {
        flex-wrap: wrap;
        div {
          margin: 20px 0;
          justify-content: space-around;
        }
      }
    }
  }
  svg {
    fill: $color-main;
    width: 20px;
    margin-right: 15px;
    margin-bottom: 5px;
    @include mq($until: xl) {
      width: 17px;
      margin-right: 10px;
      height: 20px;
    }
    @include mq($until: sm) {
      &:not(#Warstwa_1) {
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  h5 {
    margin-top: 40px;
    font-size: rem(20px);
    color: white;
    text-align: right;
    strong {
      display: block;
      text-align: left;
      color: $color-main;
    }
    @include mq($until: lg) {
      display: block;
      max-width: 75vw;
      margin: 20px auto;
    }
  }
  &> div:first-child a {
    margin-bottom: 10px;
    display: inline-block;
    @include mq($until: xl) {
      margin: 0;
    }
  }
  @include mq($until: xl) {
    &> div:last-of-type {
      margin: 0;
      max-width: 62%;
    }
  }
  @include mq($until: lg) {
    &> div:last-of-type {
      max-width: 88%;
      margin-left: 2%;
    }
  }
  @include mq($until: md) {
    &> div:last-of-type {
      margin-left: 0;
    }
  }
}

.personal-contacts {
  justify-content: space-between;
  margin-top: 80px;
  display: flex;
  &>div {
    display: flex;
    @include mq($until: sm) {
      align-items: center;
    }
    @include mq($until: xs) {
      margin-bottom: 20px;
    }
    a {
      white-space: pre;
    }
  }
  h6 {
    color: $color-main;
    white-space: nowrap;
    @include mq($until: xl) {
      margin-bottom: 7px;
    }
    @include mq($until: sm) {
      margin-bottom: 6px;
    }
  }
  @include mq($until: xs) {
    flex-flow: wrap;
    margin-top: 40px;
  }
}

.footer-logo {
  margin: 0 40px;
  @include mq($until: xl) {
    margin: 20px 15px 0;
    min-width: 235px;
  }
  @include mq($until: lg) {
    margin: 20px 5px 0;
  }
}

.pers-icon {
  svg {
    fill: $secondary-bg;
    margin-right: 15px;
    width: 115px;
    @include mq($until: xl) {
      width: 85px;
      height: 85px;
      margin-right: 12px;
    }
    @include mq($until: sm) {
      width: 6vw;
      margin-right: 20px;
    }
  }
  @include mq($until: xs) {
    &+ div a, &+ div h6 {
      margin-left: 15px;
    }
    svg {
      width: 50px;
      margin-right: 0;
    }

  }
}

