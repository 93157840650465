.subsite-top {
  background-size: cover;
  height: 48vw;
  background-position: top;
  background-repeat: no-repeat;
  margin-bottom: -11vw;
}

.subsite {
  margin-bottom: 15vw;
  @include mq($until: md) {
    margin-bottom: 120px;
  }
  h1 {
    margin-top: -6vw;
    margin-bottom: 9vw;
    max-width: 52vw;
    color: $color-main;
    @include mq($until: sm) {
      margin-top: 30px;
      max-width: 90vw;
    }
  }
}

.bg-gray {
  background-color: $secondary-bg;
}